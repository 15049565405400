<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article>
        <field
          name="codigo"
          widget="char"
          searchable
          label="Código"
          help="Código de Usuarios Asociados"
          placeholder="Cod"
          width="50px"
          :readonly="mode=='edit'"
          style="top:9px; left:30px;"
        />
        <field
          name="descripcion"
          widget="char"
          searchable
          label="Descripcion"
          help="Descripcion del Usuarios Asociados"
          placeholder="Descripcion"
          width="270px"
          style="top:9px; left:95px;"
        />

        <field
          ref="permisos"
          name="permisos"
          widget="handsontable"
          :height="268"
          :minRows="15"
          style="top:60px; left:4px; width: 648px;"
          customprop="permisos_customprop"
          :columns="[
            {name:'modelo',header:''},
            {name:'crear',header:'Crear',type:'checkbox'},
            {name:'leer',header:'Leer',type:'checkbox'},
            {name:'editar',header:'Editar',type:'checkbox'},
            {name:'eliminar',header:'Eliminar',type:'checkbox'},
          ]"
        />

        <!--<field name="permisos" widget="o2m" height="268" minrows="1" style="top:60px; left:4px; width: 648px;" customprop="permisos_customprop">
                <div name="table">
                    <div otomcol="modelo" header="" width="180"/>
                    <div otomcol="crear" header="Crear" width="80" type="checkbox"/>
                    <div otomcol="leer" header="Leer" width="80" type="checkbox"/>
                    <div otomcol="filtro_leer" header="" width="180"/>
                    <div otomcol="editar" header="Editar" width="80" type="checkbox"/>
                    <div otomcol="filtro_editar" header="" width="180"/>
                    <div otomcol="eliminar" header="Eliminar" width="80" type="checkbox"/>
                    <div otomcol="filtro_eliminar" header="" width="180"/>
                </div>
        </div>-->
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import VentaMixin from "./../components/VentaMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    var self = this;
    return {
      title: "Grupos de usuarios",
      dbAdapter: "grupo_usuario",
      primary: "codigo",
      defaultData: {},
      fields: ["wt"]
    };
  },
  mounted() {
    var self = this;
  }
};
</script>